import React from 'react'
import {  useStaticQuery, graphql } from 'gatsby';
import chroma from 'chroma-js';

export default function ClientBadge ({name:client}) {
  
  const data = useStaticQuery(graphql`
  query {
    allProjectsJson(sort: { order: DESC, fields: [timestamp] }) {
      edges {
        node {
          client
        }
      }
    }
  }
  `);

  const clients = [];
  const {edges} = data.allProjectsJson;
  edges.forEach(({node})=> {
    const {client} = node;
    if(!clients.includes(client)) {
      clients.push(client)
    }
  })

  const getClientIndex = (clientName) => {
    for(let i = 0; i < clients.length; i++){
      const name = clients[i];
      if(clientName === name){
        return i;
      }
    }
  }

  return (
    <div className={`project-client`} style={{backgroundColor: chroma.hsv(getClientIndex(client)/(clients.length-1) * 360, .6, .9).hex()}}>{client}</div>
  )
}